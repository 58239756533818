

import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';



function Menu8() {

    const navigate = useNavigate();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isMenuCollapsed, setMenuCollapsed] = useState(true);


    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        // Redirige a la ruta correspondiente al idioma seleccionado
        navigate(`/${selectedLanguage}`);
    };



    const handleToggleClick = () => {
        setMenuCollapsed(!isMenuCollapsed);

        const navbarCollapse = document.querySelector('.navbar-collapse');
        navbarCollapse.style.display = isMenuCollapsed ? 'block' : 'none';

        const subMenus = document.querySelectorAll('.sub-menu');
        subMenus.forEach((submenu) => {
            submenu.style.display = 'none';
        });
    };

    const handleSubMenuClick = (e) => {
        e.preventDefault();
        const submenu = e.target.nextElementSibling;

        const otherSubMenus = document.querySelectorAll('.sub-menu');
        otherSubMenus.forEach((otherMenu) => {
            if (otherMenu !== submenu) {
                otherMenu.style.display = 'none';
            }
        });

        if (!isMenuCollapsed && submenu) {
            submenu.style.display = submenu.style.display === 'block' ? 'none' : 'block';
        }
    };

    const handleResize = () => {
        setWindowWidth(window.innerWidth);

        if (window.innerWidth > 750) {
            setMenuCollapsed(true);
        }
    };

    useEffect(() => {
        const resizeHandler = () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(handleResize, 100);
        };

        let resizeTimer;

        window.addEventListener('resize', resizeHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    useEffect(() => {
        handleResize();
    }, [windowWidth]);

    useEffect(() => {
        // Asegurar que el menú esté colapsado al renderizar
        const navbarCollapse = document.querySelector('.navbar-collapse');
        navbarCollapse.style.display = isMenuCollapsed ? 'none' : 'block';

        const subMenus = document.querySelectorAll('.sub-menu');
        subMenus.forEach((submenu) => {
            submenu.style.display = 'none';
        });
    }, [isMenuCollapsed]);




    return (

        <main>


            {/* Barra superior con nombre de la ciudad, redes sociales y cambio de idioma */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#333', color: 'white', padding: '10px 20px 5px' }}>
                <div style={{ fontSize: '18px' }}> Moscow </div>
                <div style={{ fontSize: '20px' }}>
                    {/* Iconos de redes sociales */}
                    <i className="fab fa-vk" style={{ marginRight: '10px' }}></i>
                    <i className="fab fa-instagram" style={{ marginRight: '10px' }}></i>
                    <i className="fab fa-x-twitter" style={{ marginRight: '10px' }}></i>
                    <i className="fab fa-facebook" style={{ marginRight: '10px' }}></i>                    
                    
                </div>
                <div style={{ fontSize: '18px' }}>
                    {/* Icono y opciones para cambiar de idioma con manejo de eventos */}
                    <span role="img" aria-label="language" style={{ marginRight: '10px' }}>🌐</span>
                    <select onChange={handleLanguageChange}>
                        <option value="ru">Ру</option>
                        <option value="es">Es</option>
                        <option value="en">En</option>
                        {/* Agrega más opciones de idiomas según sea necesario */}
                    </select>
                </div>
            </div>

            <div className="main-wrapper">
                <nav className="navbar">


                    <div className="brand-and-icon">
                        <Link to="/" className="navbar-brand"> LATINO CLUB </Link>

                        <button type="button" className="navbar-toggler" onClick={handleToggleClick}>
                            <i className="fas fa-bars" />
                        </button>
                    </div>

                    <div className="navbar-collapse">
                        <ul className="navbar-nav">

                            <li>
                                <Link to="/" onClick={handleSubMenuClick}> home 8 </Link>

                               
                            </li>



                            {/*================= 2 BAILES =================*/}


                            <li>
                                <a href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    Bailemos
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </a>
                                <div className="sub-menu">
                                    {/* item */}
                                    <div className="sub-menu-item">
                                        <h4>top categories</h4>

                                        <ul>

                                            <li>
                                                <a href="#">Las mejores escuelas de baile</a>
                                            </li>
                                            <li>
                                                <a href="#">Profesores de baile</a>
                                            </li>
                                            <li>
                                                <a href="#">Bachata</a>
                                            </li>
                                            <li>
                                                <a href="#">Salsa</a>
                                            </li>
                                            <li>
                                                <a href="#">Letras</a>
                                            </li>



                                        </ul>
                                    </div>
                                    {/* end of item */}


                                    {/* item */}
                                    <div className="sub-menu-item">
                                        <h2>Siente la pasión latina</h2>

                                        <button type="button" className="btn">
                                            <a href="dance.html">Ingresa</a>
                                        </button>
                                    </div>
                                    {/* end of item */}

                                    {/* item */}
                                    <div className="sub-menu-item">
                                        <img
                                            src="assets/images/daniel-desiree.gif"
                                            alt="product image"
                                        />
                                    </div>
                                    {/* end of item */}
                                </div>
                            </li>


                            {/*=============== 3 Restaurantes =================*/}

                            <li>
                                <a href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    Restaurantes
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </a>
                                <div className="sub-menu">
                                    {/* item */}
                                    <div className="sub-menu-item">
                                        <h4>top categories</h4>
                                        <ul>
                                            <li>
                                                <a href="#">Los mejores restaurantes</a>
                                            </li>
                                            <li>
                                                <a href="#">Restaurantes Latinos</a>
                                            </li>
                                            <li>
                                                <a href="#">EVENTOS CORPORATIVOS</a>
                                            </li>
                                            <li>
                                                <a href="#">Clubes y pubs</a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* end of item */}

                                    {/* item */}
                                    <div className="sub-menu-item">
                                        <h2>El sabor que te encantará</h2>
                                        <div>
                                            <img
                                                src="assets/images/fiesta1.jpg"
                                                alt="product image"
                                            /> </div>
                                        <button type="button" className="btn">
                                            Delicioso!
                                        </button>
                                    </div>
                                    {/* end of item */}
                                    {/* item */}
                                    {/* <div className="sub-menu-item">
                                         <img
                                            src="assets/images/fiesta1.jpg"
                                            alt="product image"
                                        /> 
                                    </div> */}
                                    {/* end of item */}
                                </div>
                            </li>
                            {/*==============================================*/}
                            {/*=============== 4 IDIOMAS ====================*/}
                            {/*==============================================*/}
                            <li>
                                <a href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    Idioma español
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </a>
                                <div className="sub-menu">
                                    {/* item */}
                                    <div className="sub-menu-item">
                                        <h4>Escuelas de Español</h4>
                                        <ul>
                                            <li>
                                                <a href="#">Escuelas de Español</a>
                                            </li>
                                            <li>
                                                <a href="#">Profesores de Español</a>
                                            </li>
                                            <li>
                                                <a href="#">Traducciones y documentación</a>
                                            </li>
                                            <li>
                                                <a href="#">Inmersión Linguistica</a>
                                            </li>

                                        </ul>
                                    </div>
                                    {/* end of item */}

                                    {/* item */}
                                    <div className="sub-menu-item">
                                        <h2>Eres lo más hermoso en el mundo</h2>
                                        <button type="button" className="btn">
                                            Mírame
                                        </button>
                                    </div>
                                    {/* end of item */}


                                    {/* item */}
                                    <div className="sub-menu-item">
                                        <img
                                            src="assets/images/latinosunidos.jpg"
                                            alt="product image"
                                        />
                                    </div>
                                    {/* end of item */}
                                </div>
                            </li>
                            {/*==============================================*/}
                            {/*=============== 5 LOOK====================*/}
                            {/*==============================================*/}
                            <li>
                                <a href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    MODA
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </a>
                                <div className="sub-menu">
                                    {/* item */}
                                    <div className="sub-menu-item">
                                        <h4>top categories</h4>
                                        <ul>
                                            <li>
                                                <a href="#">Moda y belleza</a>
                                            </li>

                                            <li>
                                                <a href="#">#SoyLatina</a>
                                            </li>
                                            <li>
                                                <a href="#">Encuentra a tu gemela</a>
                                            </li>
                                            <li>
                                                <a href="#">Modelos latinos(as)</a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* end of item */}


                                    {/* item */}
                                    <div className="sub-menu-item">
                                        <h2>Eres lo más hermoso en el mundo</h2>
                                        <button type="button" className="btn">
                                            Mírame
                                        </button>
                                    </div>
                                    {/* end of item */}
                                    {/* item */}
                                    <div className="sub-menu-item">
                                        <img
                                            src="assets/images/fiesta2.jpg"
                                            alt="product image"
                                        />
                                    </div>
                                    {/* end of item */}
                                </div>
                            </li>
                            {/*==============================================*/}
                            {/*=============== 6 ACTUALIDAD ===================*/}
                            {/*==============================================*/}
                            <li>
                                <a href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    Actualidad
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </a>
                                <div className="sub-menu">
                                    {/* item */}
                                    <div className="sub-menu-item">
                                        <h4>top categories</h4>
                                        <ul>
                                            <li>
                                                <a href="#">Embajadas</a>
                                            </li>
                                            <li>
                                                <a href="#">Visas para Latinoamérica</a>
                                            </li>
                                            <li>
                                                <a href="#">Negocios</a>
                                            </li>
                                            <li>
                                                <a href="#">Promociona tu negocio</a>
                                            </li>

                                        </ul>
                                    </div>
                                    {/* end of item */}

                                    {/* item */}
                                    <div className="sub-menu-item">
                                        <h2>Somos como tú</h2>
                                        <button type="button" className="btn">
                                            Viajemos
                                        </button>
                                    </div>
                                    {/* end of item */}
                                    {/* item */}
                                    <div className="sub-menu-item">
                                        <img
                                            src="assets/images/vida-nocturna.jpg" alt="product image"
                                        />
                                    </div>
                                    {/* end of item */}
                                </div>
                            </li>
                            {/*==============================================*/}
                            {/*=============== 7 ABOUT ===================*/}
                            {/*==============================================*/}
                            <li>
                                <a href="#" className="menu-link" onClick={handleSubMenuClick}>
                                    HOLA
                                    <span className="drop-icon">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </a>
                                <div className="sub-menu">
                                    {/* item */}
                                    <div className="sub-menu-item">
                                        <h4>top categories</h4>
                                        <ul>

                                            <li>
                                                <a href="#">CLUB - MEMBRESÍA</a>
                                            </li>
                                            <li>
                                                <a href="#">Support Us</a>
                                            </li>
                                            <li>
                                                <a href="#">Latinos en Moscú</a>
                                            </li>
                                            <li>
                                                <a href="#"> DIOS ES GRANDE </a>
                                            </li>

                                        </ul>
                                    </div>
                                    {/* end of item */}

                                    {/* item */}
                                    <div className="sub-menu-item">
                                        <h2>Nos tenemos el uno al otro</h2>
                                        <button type="button" className="btn">
                                            gracias
                                        </button>
                                    </div>
                                    {/* end of item */}
                                    {/* item */}
                                    <div className="sub-menu-item">
                                        <img
                                            src="assets/images/fiesta3.jpg"
                                            alt="product image"
                                        />
                                    </div>
                                    {/* end of item */}
                                </div>
                            </li>
                            {/*==============================================*/}
                            {/*=============== 6 OFERTAS ====================*/}
                            {/*==============================================*/}
                            <li>
                                <a href="#" onClick={handleSubMenuClick} >Foro latino</a>
                            </li>
                        </ul>
                    </div>

                </nav>
            </div>
        </main>

    );
}

export default Menu8;