

import React from 'react';
 
import Menu8 from './pages/Menu8';


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
 


function App() {

  return (     

      <div className="App" >


        <Router>
          <Routes>

            <Route path='/' element={<Menu8 />} />



          </Routes>


        </Router>



      </div>   



  );

}

export default App;
